import React, { useReducer } from 'react';
import Layout from '../components/IsolatedLayout';
import SEO from '../components/seo';
import styled from 'styled-components';
import { ColorPalettes, Texter } from 'kinedu-react-components';
import { t } from '../language/config';
import TexterBasic from '../styles/themes/TexterBasic';
import MainButton from '../components/MainButton';
import { parse } from 'query-string';
import useAuth from '../hooks/useAuth';
import { WithTrans } from '../language/withTrans';

const { KineduPalette } = ColorPalettes;

const Title = styled.span`
  color: ${KineduPalette.oldkinedu};
  margin: 30px 0 10px;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
`;

const Subtitle = styled.p`
  color: ${KineduPalette.shadeMediumDark};
  margin: 10px 0 20px;
  font-size: 16px;
  text-align: center;
`;

const InputGroup = styled.div`
  & > * {
    margin-bottom: 20px;
  }
`;

const ErrorMessage = styled.p`
  color: #ff0039;
  font-size: 0.7rem;
  padding: 10px 0px;
  margin: -20px 0 0;
  text-transform: initial;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_INPUT_VALUE':
      return { ...state, [action.name]: action.value };
    default:
      throw new Error();
  }
};

const initialState = {
  password: '',
  confirmPassword: '',
};

// NOTE: Example URL
// https://staging-classrooms.kinedu.com/reset-password?reset_password_token=vA5yCCfgdzVmo6sNjztv

const ResetPassword = ({ location }) => {
  const { reset_password_token: token } = parse(location.search);
  const [data, dispatch] = useReducer(reducer, initialState);
  const { password, confirmPassword } = data;
  const { resetPassword, errors, setErrors } = useAuth();

  const handleResetPassword = () => {
    const isPassEmpty = data.password === '' ? 'Password is required' : '';
    const isConfirmPassEmpty = data.confirmPassword === '' ? 'Confirm password is required' : '';
    const bothFieldsEmpty = isPassEmpty || isConfirmPassEmpty;
    const fieldsDontMatch = ((data.password !== data.confirmPassword) && (!isPassEmpty && !isConfirmPassEmpty)) ? 'Passwords do not match' : '';
    setErrors({
      ...errors,
      passError: isPassEmpty,
      confirmPassError: isConfirmPassEmpty,
      generalError: fieldsDontMatch,
    });
    if (bothFieldsEmpty || fieldsDontMatch) return;
    resetPassword(token, password, confirmPassword, 'reset-password');
  };

  const handleOnChange = (name, value) => {
    dispatch({ type: 'SET_INPUT_VALUE', name, value });
  };

  return (
    <Layout>
      <SEO title="Forgot password" />
      <Title>{t('RESET_PASS_VIEW.TITLE')}</Title>
      <Subtitle>{t('RESET_PASS_VIEW.SUBTITLE')}</Subtitle>
      <InputGroup>
        <Texter
          theme={TexterBasic}
          name="password"
          label={t('RESET_PASS_VIEW.NEW_PASSWORD')}
          type="password"
          onChange={(value) => handleOnChange('password', value)}
          value={data.password}
        />
        <ErrorMessage>{t(errors.passError)}</ErrorMessage>
        <Texter
          theme={TexterBasic}
          name="confirmpassword"
          label={t('RESET_PASS_VIEW.CONFIRM_PASSWORD')}
          type="password"
          onChange={(value) => handleOnChange('confirmPassword', value)}
          value={data.confirmPassword}
        />
        <ErrorMessage>{t(errors.generalError || errors.confirmPassError)}</ErrorMessage>
        <MainButton
          fill
          onClick={handleResetPassword}
        >
          {t('CHANGE_PASS')}
        </MainButton>
      </InputGroup>
    </Layout>
  );
};

export default WithTrans()(ResetPassword);
